import React, {Component} from "react";
import {connect} from "react-redux";
import Header from "../../Header/header";
import bbanner from "../../../images/compter.jpg";
import axios from "axios";
import {serverLink, shortCode} from "../../../resources/url";
import {Oval} from "react-loader-spinner";
import {setFacultyData} from "../../../actions/facultyactions";
import {accessControl} from "../../../permission/permission";

class FacultyDashboard extends Component {
    constructor(props) {
        super(props);
        accessControl([1, 2, 3, 4]);
        this.state = {
            isLoading: true,
            facultyDetails: [],
            FacultyName: this.props.facultyData.FacultyName,
            FacultyDean: this.props.facultyData.FacultyDean,
            FacultyColor: this.props.facultyDetails.length === 0 ? this.props.facultyData.FacultyColour :  this.props.facultyDetails[0].FacultyColor,
            FacultyCode:  this.props.isFaculty  ? this.props.facultyData.FacultyCode : "General",
            FacultyImage: this.props.facultyDetails.length === 0 ? "" : this.props.facultyDetails[0].FacultyImage,
            CoursesCount: 0,
            NewsCount: 0,
            EventCount: 0
        }
    }
    componentDidMount() {
        this.getFacultyCount();
    }

        async getFacultyCount() {
            await axios.get(`${serverLink}general/faculty/dashboard/count/${this.props.facultyData.FacultyCode}`)
                .then(data => {
                    const result = data.data;
                    this.setState({
                        CoursesCount: result.CoursesCount.CourseCount,
                        NewsCount: result.NewsCount.NewsCount,
                        EventCount: result.EventCount.EventCount,
                    });
                });

            this.setState({isLoading: false})
        }

    render() {
        return (
            <>
            <Header isFaculty={true}/>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                {/* <!--begin::Toolbar--> */}
                <div className="toolbar" id="kt_toolbar">
                    {/* <!--begin::Container--> */}
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        {/* <!--begin::Page title--> */}
                        <div data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">
                            {/* <!--begin::Title--> */}
                            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">Dashboard
                                {/* <!--begin::Separator--> */}
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"/>
                                {/* <!--end::Separator--> */}
                            </h1>
                            {/* <!--end::Title--> */}
                        </div>
                        {/* <!--end::Page title--> */}
                        {/* <!--begin::Actions--> */}
                        {/* <!--end::Actions--> */}
                    </div>
                    {/* <!--end::Container--> */}
                </div>
                {/* <!--end::Toolbar--> */}
                {/* <!--begin::Post--> */}
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    {/* <!--begin::Container--> */}
                    <div id="kt_content_container" className="container-fluid">
                        {/* <!--begin::Row--> */}
                        {this.state.isLoading ?
                            <div style={{
                                margin: 'auto',
                                position: 'relative',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                height: '400px'
                            }}>
                                <Oval
                                    type="Oval"
                                    color="#eeeeee"
                                    height={50}
                                    width={50}
                                    secondaryColor="teal"
                                    timeout={15000} //3 secs
                                />
                            </div>
                            :
                            <div className="card mb-5 mb-xl-10">
                                <div className="card-body py-10">
                                    <h2 className="mb-5 fw-bolder text-uppercase bi-pin-angle-fill "
                                        style={{textAlign: 'left'}}> {this.state.FacultyName}</h2>
                                    <div className="" style={{height: '400px'}}>
                                        {
                                            this.state.FacultyImage !=="" ?
                                                <img src={this.state.FacultyImage !== null ? this.state.FacultyImage.includes("simplefileupload") ? `${this.state.FacultyImage}` : `${serverLink}public/uploads/${shortCode}/faculty_uploads/${this.state.FacultyImage}` : ""} className="img-thumbnail"  style={{backgroundColor: '#eeeeee', height: '400px', width: '100%'}} alt="Banner"/>
                                                :
                                                <img alt="Logo" src={bbanner} className="img-thumbnail" style={{backgroundColor: '#eeeeee', height: '400px', width: '100%'}}/>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <div
                                                className="card card-dashed  border-gray-500 flex-center min-w-175px my-3 p-6">
                                                <span className="fs-4 fw-bold text-info pb-1 px-2">Courses</span>
                                                <span className="fs-lg-2tx fw-bolder d-flex justify-content-center">
													<span data-kt-countup="true"
                                                          data-kt-countup-value={this.state.CoursesCount}>{this.state.CoursesCount}</span></span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card card-dashed border-gray-500 flex-center min-w-175px my-3 p-6">
                                                <span className="fs-4 fw-bold text-success pb-1 px-2">News</span>
                                                <span className="fs-lg-2tx fw-bolder d-flex justify-content-center">
													<span data-kt-countup="true"
                                                          data-kt-countup-value={this.state.NewsCount}>{this.state.NewsCount}</span></span>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card card-dashed  border-gray-500 flex-center min-w-175px my-3 p-6">
                                                <span className="fs-4 fw-bold text-danger pb-1 px-2">Event</span>
                                                <span className="fs-lg-2tx fw-bolder d-flex justify-content-center">
													<span data-kt-countup="true"
                                                          data-kt-countup-value={this.state.EventCount}>{this.state.EventCount}</span></span>
                                            </div>
                                        </div>

                                    </div>

                                    <div
                                        className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
                                <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     viewBox="0 0 24 24" fill="none">
													<path
                                                        d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                                                        fill="black"/>
													<path opacity="0.3"
                                                          d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                                                          fill="black"/>
												</svg>
											</span>
                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                            <div className="mb-3 mb-md-0 fw-bold " style={{textAlign: 'left'}}>
                                                <h4 className="text-gray-900 fw-bolder text-capitalize">  {this.state.FacultyDean}</h4>
                                                <div className="fs-6 text-gray-700 pe-7">
                                                    Dean, {this.state.FacultyName}.
                                                </div>
                                            </div>
                                            <a href="/faculty/content"  className="btn btn-primary px-6 align-self-center text-nowrap"
                                               >Edit Content</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* <!--end::Container--> */}
                    </div>
                    {/* <!--end::Post--> */}
                </div>
                {/* <!--end::Content--> */}
            </div>
                </>
        )
    }
}

const mapDisptachToProps = (dispatch) => {
    return {
        setOnFacultyData: (p) => {
            dispatch(setFacultyData(p));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        facultyData: state.facultyDetails,
        facultyDetails: state.facultyData,
    };
};
export default connect(mapStateToProps, mapDisptachToProps)(FacultyDashboard);